import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
        path: '/:propertyId?/:clientId?/:officeId?/:contactId?',
  }
];

export default AppRoutes;
